"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerSearchParameter = exports.ApplicationSearchParameter = exports.UserSearchParameter = void 0;
var UserSearchParameter;
(function (UserSearchParameter) {
    UserSearchParameter["NAME"] = "NAME";
    UserSearchParameter["EMAIL"] = "EMAIL";
    UserSearchParameter["USERNAME"] = "USERNAME";
})(UserSearchParameter || (exports.UserSearchParameter = UserSearchParameter = {}));
var ApplicationSearchParameter;
(function (ApplicationSearchParameter) {
    ApplicationSearchParameter["DENTALPROVIDER"] = "DENTALPROVIDER";
    ApplicationSearchParameter["MYLINCOLNPORTAL"] = "MYLINCOLNPORTAL";
    ApplicationSearchParameter["ALL"] = "ALL";
})(ApplicationSearchParameter || (exports.ApplicationSearchParameter = ApplicationSearchParameter = {}));
var CustomerSearchParameter;
(function (CustomerSearchParameter) {
    CustomerSearchParameter["CUSTOMERID"] = "CUSTOMER_ID";
    CustomerSearchParameter["CUSTOMERNAME"] = "CUSTOMER_NAME";
})(CustomerSearchParameter || (exports.CustomerSearchParameter = CustomerSearchParameter = {}));
