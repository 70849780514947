"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environment = void 0;
var Environment;
(function (Environment) {
    Environment["LOCAL"] = "LOCAL";
    Environment["DEV"] = "DEV";
    Environment["TEST"] = "TEST";
    Environment["PROD"] = "PROD";
})(Environment || (exports.Environment = Environment = {}));
