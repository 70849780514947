"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dentalProviderPortalLink = exports.notificationsLink = exports.userReportsLink = exports.customerPortalLink = exports.supportLink = exports.customerAdminLink = exports.docAdminLink = exports.userAdminLink = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const EnvUtils_1 = require("../../../shared/src/utils/EnvUtils");
exports.userAdminLink = {
    href: '/user',
    icon: 'employees',
    text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Assign roles to external users. Review user permissions. Deactivate users." }),
    title: 'User Administration',
};
exports.docAdminLink = {
    href: '/document',
    icon: 'documents',
    text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Assign permissions to documents to display on the portal." }),
    title: 'Document Administration',
};
exports.customerAdminLink = {
    href: `${(0, EnvUtils_1.getEnvVariable)('lmAdminUrl')}/customer/search`,
    icon: 'gear',
    text: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Set up new portals and manage existing portals at the customer level. Ability to manage master content of the portal." })),
    title: 'Customer Administration',
};
exports.supportLink = {
    href: `${(0, EnvUtils_1.getEnvVariable)('lmAdminUrl')}/support`,
    icon: 'life-ring-regular',
    text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Retry failed document uploads." }),
    title: 'General Support',
};
exports.customerPortalLink = {
    href: `${(0, EnvUtils_1.getEnvVariable)('externalCustomerPortal')}`,
    icon: 'building-solid',
    text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "View portals for customers you have access to." }),
    title: 'Customer Portals',
};
exports.userReportsLink = {
    href: `${(0, EnvUtils_1.getEnvVariable)('lmAdminUrl')}/reports`,
    icon: 'file-excel-regular',
    text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Generate or View customer or user listings" }),
    title: 'Reports',
};
exports.notificationsLink = {
    href: `${(0, EnvUtils_1.getEnvVariable)('lmAdminUrl')}/notifications`,
    icon: 'mail',
    text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Configure banner messages" }),
    title: 'Notifications',
};
exports.dentalProviderPortalLink = {
    href: `${(0, EnvUtils_1.getEnvVariable)('dentalProviderPortalUri')}`,
    icon: 'ortho-services',
    text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Access the dental provider portal" }),
    title: 'Dental Provider Portal',
};
