"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationPermissions = exports.userReportApplicationPermissions = exports.dentalPortalApplicationPermissions = exports.supportApplicationPermissions = exports.docAdminApplicationPermissions = exports.userAdminApplicationPermissions = exports.customerAdminApplicationPermissions = void 0;
exports.customerAdminApplicationPermissions = ['customerAdmin'];
exports.userAdminApplicationPermissions = ['userAdminExternal', 'userAdminAll'];
exports.docAdminApplicationPermissions = ['docAdminGeneral', 'docAdminReports'];
exports.supportApplicationPermissions = ['supportDocs'];
exports.dentalPortalApplicationPermissions = ['dentalPortalAccess', 'dentalPortalPreregistration'];
exports.userReportApplicationPermissions = ['userAdminAll'];
exports.notificationPermissions = ['customerAdmin'];
